import { useEffect, useState } from "react";
import "./landingPage.scss";
import { Link } from "react-router-dom";
import logo from '../../assets/logo.svg';
import { postNotifyMe } from "../../services/comingSoonServices";

export default function ComingSoon() {

    const [email, setEmail] = useState<string>('');
    const [agree, setAgree] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<any>(null);
    const [sentEmail, setSentEmail] = useState<string | null>(null); // New state for the sent email
    const [error, setError] = useState<any>(null);

    const handleSubscribe = async (e: any) => {
        e.preventDefault();
        if (!agree) {
            setError("You must agree to the terms before subscribing.");
            return;
        }

        setLoading(true);
        setError(null);

        const { data, error: apiError } = await postNotifyMe(email);

        if (apiError) {
            setError(apiError);
        } else {
            setResult(data);
            setSentEmail(email); // Store the sent email
            setEmail(""); // Clear the input field
        }

        setLoading(false);
    };

    return (
        <div className="launch-page">
            <div className="left-section">
                <div className="content">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} />
                        </Link>
                    </div>
                    <h1 className="cta">Get Ready! <br />We're Launching Soon!</h1>
                    <form onSubmit={handleSubscribe} className="form-container">
                        <p className="subtext">Get notified when we go live!</p>

                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="agree"
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                            />
                            <label htmlFor="agree">
                                I agree that my email address may be used to send updates, promotional offers, and marketing communications from <strong>housez.ae</strong>.
                            </label>
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Sending...' : 'Subscribe'}
                        </button>

                        {result && sentEmail && (
                            <p className="success-message">
                                Email sent successfully to <strong>{sentEmail}</strong>!
                            </p>
                        )}
                        {error && <p className="error-message">{error}</p>}
                    </form>
                </div>
                <div className="socials">
                    <div className="social-icons">
                        <a target="_blank" href="https://www.facebook.com/profile.php?id=61564134832261&mibextid=ZbWKwL">
                            <img src={'/images/apts/icons/Facebook.svg'} alt="Facebook" />
                        </a>
                        <a target="_blank" href="https://www.instagram.com/housez.ae?igsh=OTBycTFydTZ4cTZi">
                            <img src={'/images/apts/icons/Instagram.svg'} alt="Instagram" />
                        </a>
                        <a target="_blank" href="https://youtube.com/@housezae?si=yHHnolARBaFlwobM">
                            <img src={'/images/apts/icons/Youtube.svg'} alt="YouTube" />
                        </a>
                        <a target="_blank" href="https://www.tiktok.com/@housez.ae?_t=8ov4Dexpt7Y&_r=1">
                            <img src={'/images/apts/icons/Tiktok.svg'} alt="Tiktok" />
                        </a>
                        <a target="_blank" href="https://x.com/housezAE?t=KmeN80TMtt2t1745ulpnNg&s=08">
                            <img src={'/images/apts/icons/TwitterX.svg'} alt="TwitterX" />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/housez-ae/">
                            <img src={'/images/apts/icons/Linkedin.svg'} alt="Linkedin" />
                        </a>
                    </div>
                    <p className="copyrights">© Copyrights HouseZ 2024 | All Rights Reserved</p>
                </div>
            </div>
            <div className="right-section">
                <img src="/images/Logo-3D.png" alt="3D Design" className="hero-image" />
            </div>
        </div>
    );
}
